<template>
    <v-container>
        <SectionTitle title="Produtos">
            <template slot="action-buttons">
              <v-btn class="mr-2" x-large color="primary" icon @click="() => expandItems = !expandItems">
                <v-icon size="20">{{ expandItems ? 'fas fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt' }}</v-icon>
              </v-btn>
            </template>
        </SectionTitle>

        <v-row dense>
          <v-col cols="12" lg="2">
            <v-text-field
              v-model="filter.product_code"
              label="Código do produto"
              placeholder="MB00000"
              dense
              outlined
              rounded
              hide-details
            />
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="filter.product_name"
              label="Nome do produto"
              placeholder="Digite o nome do produto"
              dense
              outlined
              rounded
              hide-details
            />
          </v-col>
          <v-col cols="12" lg="4">
            <v-select
              v-model="filter.factor_id"
              @focus.once="getFactors"
              label="Tabela fator"
              :items="factor.items"
              item-text="cost"
              item-value="id"
              dense
              outlined
              rounded
              hide-details
            >
              <template v-slot:no-data>
                <div class="pa-4" v-if="factor.isLoading">
                  <v-progress-linear indeterminate color="primary" />
                </div>
                <h6 class="text-subtitle-2 text-center" v-else>Nenhum fator encontrado</h6>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" lg="2">
            <v-select
              v-model="filter.product_status"
              label="Status do produto"
              :items="[{title: 'Ativo', value: true}, {title: 'Inativo', value: false}]"
              item-text="title"
              item-value="value"
              dense
              outlined
              rounded
              hide-details
            />
          </v-col>
          <v-col cols="12" lg="2">
            <v-text-field
              v-model="filter.vendor_code"
              label="Código do fornecedor"
              dense
              outlined
              rounded
              hide-details
            />
          </v-col>
          <v-col cols="12" lg="2">
            <v-select
              v-model="filter.vendor_status"
              label="Status do fornecedor"
              :items="vendorStatus"
              item-text="title"
              item-value="id"
              dense
              outlined
              rounded
              hide-details
            >
              <template v-slot:item="{ item }">
                <v-list-item-avatar size="20">
                  <v-icon small :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" lg="2">
            <v-select
              v-model="filter.vendor_choosed"
              label="Escolhido"
              :items="[{title: 'Sim', value: 'yes'}, {title: 'Não', value: 'no'}]"
              item-text="title"
              item-value="value"
              dense
              outlined
              rounded
              hide-details
            />
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="filter.vendor_observation"
              label="Observação"
              dense
              outlined
              rounded
              hide-details
            />
          </v-col>
        </v-row>
        <v-row align="end" dense class="mt-5">
          <v-col cols="12" lg="4">
            <v-row dense>
              <v-col cols="12">
                <div class="d-flex">
                  <v-icon small color="primary" left>fas fa-calendar-alt</v-icon>
                  <h6 class="caption">Data de atualização</h6>
                </div>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="filter.updated_at_from"
                  label="De:"
                  placeholder="00/00/0000"
                  rounded
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="filter.updated_at_to"
                  label="Até:"
                  placeholder="00/00/0000"
                  rounded
                  outlined
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6">
            <v-row dense>
              <v-col cols="12">
                <div class="d-flex">
                  <v-icon small color="primary" left>fas fa-hand-holding-usd</v-icon>
                  <h6 class="caption">Custo fixo</h6>
                </div>
              </v-col>
              <v-col cols="12" lg="4">
                <v-text-field
                  v-model="filter.fixed_cost_from"
                  label="De:"
                  placeholder="R$00,00"
                  v-mask="['#,##', '##,##', '###,##']"
                  rounded
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" lg="4">
                <v-text-field
                  v-model="filter.fixed_cost_to"
                  label="Até:"
                  placeholder="R$00,00"
                  v-mask="['#,##', '##,##', '###,##']"
                  rounded
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" lg="4" class="d-flex">
                <v-btn @click="clearFilter" rounded outlined color="primary" class="mr-2">Limpar filtro</v-btn>
                <v-btn @click="filterVendorProducts" rounded color="primary">Buscar</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-simple-table class="mt-15" style="position: relative">
          <template v-slot:default>
              <v-alert
                :value="successEditing"
                dense
                text
                style="position: absolute; top: -35px"
                type="success"
                transition="slide-x-transition"
                class="font-weight-bold"
              >
                {{ alertMessage }}
              </v-alert>
                <thead>
                    <tr>
                        <th
                            v-for="(item, idx) in headers"
                            :key="idx"
                            :class="`text-${item.align}`"
                        >
                            {{ item.label }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in items" :key="idx">
                        <td style="width: 50px">
                          <v-icon v-if="item.product.vendor_selected == vendor.id" color="primary">fas fa-check-circle</v-icon>
                        </td>
                        <td class="py-2">
                            <v-avatar :size="expandItems ? 130 : 30" rounded class="elevation-2">
                                <v-img v-if="item.product_image"  :src="$store.state.imagePath + item.product_image.src" />
                                <v-icon small v-else color="primary">fas fa-box</v-icon>
                            </v-avatar>
                        </td>
                        <td>
                          <div><u class="primary--text"><a target="_blank" :href="`${$store.state.hostFront}stock/products/${item.product.id}`">{{ item.product.product_name }}</a></u></div>
                          <div>{{ item.product.product_reference }}</div>
                        </td>
                        <td class="text-center">
                            <v-item-group @change="v => setStatus(v, item.vendor_id, item.product.id, idx)" v-model="item.vendor_status" mandatory>
                                <v-container class="d-flex justify-center">
                                    <v-item v-for="(item, idx) in vendorStatus" :key="idx" :disabled="loadingStatus" :value="item.id" v-slot="{ active, toggle }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-card
                                                    :color="active ? item.color : 'grey lighten-3'"
                                                    class="d-flex align-center justify-center mx-1"
                                                    height="30"
                                                    width="30"
                                                    v-on="on"
                                                    v-bind="attrs"
                                                    @click="toggle"
                                                    dark
                                                >
                                                    <v-progress-circular v-if="loadingStatus" color="primary" indeterminate size="20" />
                                                    <v-icon v-else small :color="active ? 'white' : 'grey lighten-1'">{{ item.icon }}</v-icon>
                                                </v-card>
                                            </template>
                                            <span>{{ item.title }}</span>
                                        </v-tooltip>
                                    </v-item>
                                </v-container>
                            </v-item-group>
                        </td>
                        <td>
                          <span>
                            {{ item.vendor_code }}

                            <v-menu max-width="350" :value="openedCodeEditMenu === idx" offset-y :close-on-click="false" :close-on-content-click="false">
                                <template v-slot:activator="{ attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        @click="openedCodeEditMenu = idx"
                                        color="primary"
                                        class="edit-icon"
                                        :ripple="false"
                                        size="15"
                                    >
                                        fas fa-edit
                                    </v-icon>
                                </template>
                                <v-card>
                                    <v-card-text class="d-flex align-center">
                                        <v-text-field
                                            :value="item.vendor_code"
                                            @input="v => defineCode(v)"
                                            autofocus
                                            placeholder="Código do fornecedor"
                                            solo
                                            flat
                                            dense
                                            hide-details
                                        />
                                        <v-btn class="mr-2" @click="openedCodeEditMenu = null" rounded small outlined color="primary">Cancelar</v-btn>
                                        <v-btn rounded color="primary" @click="updateCode(item.cost_tables[0].view_id, idx)" :loading="loadingCodeEdit == idx" small :ripple="false">salvar</v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                          </span>
                        </td>
                        <td class="text-center" v-if="item.cost_tables[0].is_fixed == 1">
                          <span>
                            {{ parseFloat(item.cost_tables[0].rows[0].cost.toFixed(2)).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}
                            
                            <v-menu max-width="350" :value="openedCostEditMenu === idx" offset-y :close-on-click="false" :close-on-content-click="false">
                                <template v-slot:activator="{ attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        @click="openedCostEditMenu = idx"
                                        color="primary"
                                        class="edit-icon"
                                        :ripple="false"
                                        size="15"
                                    >
                                        fas fa-edit
                                    </v-icon>
                                </template>
                                <v-card>
                                    <v-card-text class="d-flex align-center">
                                        <v-text-field
                                            :value="item.cost_tables[0].rows[0].cost.toFixed(2).toLocaleString('pt-BR')"
                                            @input="v => defineCost(v)"
                                            autofocus
                                            prefix="R$"
                                            placeholder="R$0,00"
                                            v-mask="['#,##', '##,##']"
                                            solo
                                            flat
                                            dense
                                            hide-details
                                        />
                                        <v-btn class="mr-2" @click="openedCostEditMenu = null" rounded small outlined color="primary">Cancelar</v-btn>
                                        <v-btn rounded color="primary" @click="updateCost(item.cost_tables[0].rows[0].id, idx)" :loading="loadingCostEdit == idx" small :ripple="false">salvar</v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                          </span>
                        </td>
                        <td v-else class="text-center">
                          <v-icon small color="primary">fas fa-table</v-icon>
                        </td>
                        <td style="max-width: 300px">
                          <div style="max-height: 70px;" class="overflow-auto">
                            {{ item.vendor_description }}
                          </div>
                        </td>
                        <td style="max-width: 100px;">
                          <v-menu :value="openedDescriptionEditMenu === idx" offset-y :close-on-click="false" :close-on-content-click="false">
                              <template v-slot:activator="{ attrs }">
                                  <v-icon
                                      v-bind="attrs"
                                      @click="openedDescriptionEditMenu = idx"
                                      color="primary"
                                      class="edit-icon"
                                      :ripple="false"
                                      size="15"
                                  >
                                      fas fa-edit
                                  </v-icon>
                              </template>
                              <v-card>
                                  <v-card-text>
                                      <v-textarea
                                          :value="item.vendor_description"
                                          @input="v => defineDescription(v)"
                                          autofocus
                                          placeholder="Digite a observação para o fornecedor"
                                          solo
                                          flat
                                          dense
                                          rows="3"
                                          style="width: 400px"
                                          hide-details
                                      />
                                      <v-divider />
                                  </v-card-text>
                                  <v-card-actions class="pt-1">
                                    <v-spacer />
                                    <v-btn class="mr-2" @click="openedDescriptionEditMenu = null" rounded small outlined color="primary">Cancelar</v-btn>
                                      <v-btn rounded color="primary" @click="updateDescription(item.cost_tables[0].view_id, idx)" :loading="loadingDescriptionEdit == idx" small :ripple="false">salvar</v-btn>
                                  </v-card-actions>
                              </v-card>
                          </v-menu>
                        </td>
                        <td class="text-right">{{ item.updated_at.slice(0, 10).split('-').reverse().join('/') }}</td>
                        <td class="text-right">
                          <v-btn :disabled="item.product.vendor_selected == vendor.id" @click="updateProductSelectedVendor(item, idx)" small color="primary" outlined rounded>
                            selecionar
                          </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-container>
</template>

<script>
export default {
  components: {
    SectionTitle: () => import("@/components/SectionTitle"),
  },
  props: {
    vendor: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    products:null,
    filter: {},
    expandItems: null,
    openedDescriptionEditMenu: null,
    definedDescription: null,
    loadingDescriptionEdit: null,
    openedCodeEditMenu: null,
    loadingCodeEdit: null,
    openedCostEditMenu: null,
    loadingCostEdit: null,
    successEditing: false,
    loadingStatus: false,
    alertMessage: '',
    factor: {
      items: [],
      isLoading: true
    },
    items: [],
    headers: [
        { label: '', align: 'center' },
        { label: 'Imagem' },
        { label: 'Produto' },
        { label: 'Status', align: 'center' },
        { label: 'Cód. Fornecedor' },
        { label: 'Custo', align: 'center' },
        { label: 'Observação' },
        { label: '' },
        { label: 'Data atlz.', align: 'right' },
        { label: '', align: 'right' },
    ],
    vendorStatus: [
      { id: 1, title: "Ok", icon: "fas fa-check-circle", color: 'success' },
      { id: 2, title: "Consultar", icon: "fas fa-search", color: 'blue' },
      { id: 3, title: "Sem estoque", icon: "fas fa-box-open", color: 'error' },
      { id: 4, title: "Fora de linha", icon: "fas fa-times-circle", color: 'grey' },
    ],
    definedCost: 0.0
  }),
  methods: {
    filterVendorProducts(){
      this.$api.post('products/vendor/filter', {...this.filter, vendor_id: this.vendor.id})
        .then(res => {
          this.items = this.normalizeProducts(res.data.data)
        })
        .catch(err => {
          console.error(err);
        })
    },
    clearFilter(){
      this.filter = {}
      this.getProducts()
    },
    getProducts(){
      this.$api
        .post("product_vendors",{id:this.vendor.id})
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFactors(){
      this.$api.get('product_factors')
        .then(res => {
          this.factor.items = res.data.data
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.factor.isLoading = false
        })
    },
    getFilteredProducts(obj) {
      this.$api
        .post("products/filter", obj)
        .then((res) => {
          this.dataTable.items = res.data.data;
        })
        .catch(() => {
          this.dataTable.items = [];
        })
        .finally(() => {
          this.dataTable.isLoading = false;
        });
    },
    toggleAlt(){
      this.openedCostEditMenu = null
    },
    updateCost(id, idx){
      this.loadingCostEdit = idx
      this.$api.patch('update_vendor_finance_cost', { table_id: id, cost: this.definedCost, view_id: this.items[idx].cost_tables[0].view_id })
        .then(() => {
          this.openedCostEditMenu = null

          this.items[idx].cost_tables[0].rows[0].cost = this.definedCost

          this.items[idx].updated_at = new Date(Date.now()).toISOString()

          this.alertMessage = 'Custo fixo alterado com sucesso'
          this.successEditing = true

          setTimeout(() => {
            this.successEditing = false
          }, 1000);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCostEdit = null
        })
    },
    updateDescription(id, idx){
      this.loadingDescriptionEdit = idx
      this.$api.patch('update_vendor_description', { view_vendor_id: id, description: this.definedDescription })
        .then(() => {
          this.openedDescriptionEditMenu = null

          this.items[idx].vendor_description = this.definedDescription

          this.items[idx].updated_at = new Date(Date.now()).toISOString()

          this.alertMessage = 'Observação do fornecedor alterada com sucesso'
          this.successEditing = true

          setTimeout(() => {
            this.successEditing = false
          }, 1000);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingDescriptionEdit = null
        })
    },
    updateCode(id, idx){
      this.loadingCodeEdit = idx
      this.$api.patch('update_vendor_finance_code', { view_vendor_id: id, code: this.definedCode })
        .then(() => {
          this.openedCodeEditMenu = null

          this.items[idx].vendor_code = this.definedCode

          this.items[idx].updated_at = new Date(Date.now()).toISOString()

          this.alertMessage = 'Código do fornecedor alterado com sucesso'
          this.successEditing = true

          setTimeout(() => {
            this.successEditing = false
          }, 1000);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCodeEdit = null
        })
    },
    updateProductSelectedVendor(item, idx){
      this.$api.patch('products/update-selected-vendor', { product_id: item.product.id, vendor_id: this.vendor.id, view_id: item.cost_tables[0].view_id })
        .then(() => {
          item.product.vendor_selected = this.vendor.id

          this.alertMessage = 'Fornecedor selecionado com sucesso'
          this.successEditing = true

          this.items[idx].updated_at = new Date(Date.now()).toISOString()

          setTimeout(() => {
            this.successEditing = false
          }, 1000);
        })
        .catch(err => {
          console.error(err);
        })
    },
    defineDescription(value){
      this.definedDescription = value
    },
    defineCost(value){
      if(value.includes(',')){
        let newvalue = parseFloat(value.replace(',', '.'))
        this.definedCost = newvalue
      }else{
        this.definedCost = parseFloat(value)
      }
    },
    defineCode(value){
      this.definedCode = value
    },
    normalizeProducts(data){
      data.map(val => {
        val.product_image = val.product_images[0]
        val.cost_tables = val.product_vendors[0].cost_tables
        val.vendor_status = val.product_vendors[0].vendor_status
        val.vendor_code = val.product_vendors[0].vendor_code
        val.product = {
          product_name: val.product_name,
          product_reference: val.product_reference,
          vendor_selected: val.vendor_selected,
        }
      })

      return data
    },
    setStatus(value, vendor_id, product_id, idx){
      this.loadingStatus = true
      this.$api.patch('products/vendor-status', { vendor_id: vendor_id, product_id: product_id, status: value })
        .then(() => {
          this.alertMessage = 'Status atualizado com sucesso'
          this.successEditing = true

          this.items[idx].updated_at = new Date(Date.now()).toISOString()

          setTimeout(() => {
            this.successEditing = false
          }, 1000);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingStatus = false
        })
    }
  },
  created() {
    this.getProducts()
  },
}
</script>